@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: avenir next,avenir,sans-serif;
  }

  .button {
    @apply rounded-md inline-block px-4 py-2 text-white font-medium hover:opacity-80 cursor-pointer bg-[#e5a300];
  }

  .button.hollow {
    @apply bg-white border-2 border-[#13559e] text-[#13559e];
  }
}
